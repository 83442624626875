/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { PRODUCT_TYPE } from '../../constants/config';
import Price from '../Price';
import s from './Card.scss';
import Label from '../Label';
import { shopMessages as messages } from '../../defineMessages';

const Card = ({
  productTitle,
  productType,
  productShortDescription,
  productLogo,
  retailPrice,
  price,
  link,
  hoverType,
}) => {
  const classNames = cx(s.wrapper, hoverType && s[`hover-type--${hoverType}`]);

  return (
    <link.type {...link.props} className={classNames}>
      <div className={s.image}>
        {productLogo ? (
          <img src={productLogo} alt={productTitle} />
        ) : (
          <span className={s.noLogo}>{productTitle}</span>
        )}
      </div>
      <div className={s.body}>
        <Label tag="h3" bold className={s.title}>
          {productTitle}
        </Label>
        {productShortDescription && (
          <Label tag="p" className={s.excerpt} subdued>
            {productShortDescription}
          </Label>
        )}
      </div>
      <div className={s.price}>
        {!!retailPrice && !PRODUCT_TYPE.physicalProduct && (
          <Label size="xs" className={s.retailPrice}>
            <FormattedMessage {...messages.retailPrice} />{' '}
            <Price value={retailPrice.value} />
          </Label>
        )}
        <Label bold>
          <FormattedMessage
            {...(productType === PRODUCT_TYPE.giftCard
              ? messages.from
              : messages.yourPrice)}
          />{' '}
          <Price value={price} />
        </Label>
      </div>
    </link.type>
  );
};

Card.propTypes = {
  productTitle: PropTypes.string.isRequired,
  productShortDescription: PropTypes.string.isRequired,
  productLogo: PropTypes.string,
  price: PropTypes.number.isRequired,
  link: PropTypes.node.isRequired,
  productType: PropTypes.string,
  retailPrice: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  hoverType: PropTypes.string,
};

Card.defaultProps = {
  retailPrice: null,
  hoverType: '',
  productType: '',
  productLogo: '',
};

export default withStyles(s)(Card);
