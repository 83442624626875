import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './Carousel.scss';
import Clickable from '../Clickable';
import { ICONS } from '../../constants';

class Carousel extends PureComponent {
  state = {
    current: 0,
  };

  componentDidMount() {
    if (this.props.autoPlay) {
      this.interval = setInterval(
        () => this.nextSlide(),
        this.props.autoPlayInterval,
      );
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  nextSlide() {
    if (this.props.children?.length > 0) {
      this.setState(state => ({
        current: (state.current + 1) % this.props.children?.length,
      }));
    }
  }

  render() {
    const {
      children,
      className,
      rootClass,
      dotsClass,
      dots,
      arrows,
      arrowsClass,
      fadeInArrows,
    } = this.props;
    const { current } = this.state;
    if (!children) {
      return null;
    }
    return (
      <div className={cx(s.root, rootClass && rootClass, className)}>
        <div className={s.hide}>
          <div
            className={s.wrapper}
            style={{ width: `${children.length * 100}%` }}
          >
            {children.map((Child, index) => (
              <div
                key={index.toString()}
                className={cx(s.item, current === index && s['item--active'])}
              >
                {Child}
              </div>
            ))}
          </div>
        </div>

        {arrows && (
          <div className={cx(s.arrows, arrowsClass && arrowsClass)}>
            {current > 0 && (
              <Clickable
                inline
                className={cx(
                  s.arrow,
                  s.arrow__back,
                  fadeInArrows && s.hoverEffect,
                )}
                onClick={() => this.setState({ current: current - 1 })}
                icon={{
                  name: ICONS.ARROW_LB_LEFT,
                  width: '20',
                  height: '34',
                  color: '#FFF',
                  viewBox: '0 0 20 34',
                }}
              />
            )}
            {current + 1 < children.length && (
              <Clickable
                inline
                className={cx(
                  s.arrow,
                  s.arrow__next,
                  fadeInArrows && s.hoverEffect,
                )}
                onClick={() => this.setState({ current: current + 1 })}
                icon={{
                  name: ICONS.ARROW_LB_RIGHT,
                  width: '20',
                  height: '34',
                  color: '#FFF',
                  viewBox: '0 0 20 34',
                }}
              />
            )}
          </div>
        )}
        {dots && (
          <div className={cx(s.dots, dotsClass && dotsClass)}>
            {children.map((_, index) => (
              <Clickable
                key={index.toString()}
                className={cx(s.dot, current === index && s['dot--active'])}
                onClick={() => this.setState({ current: index })}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  rootClass: PropTypes.string,
  dotsClass: PropTypes.string,
  arrowsClass: PropTypes.string,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  fadeInArrows: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPlayInterval: PropTypes.number,
};

Carousel.defaultProps = {
  className: undefined,
  rootClass: undefined,
  dotsClass: undefined,
  arrowsClass: undefined,
  dots: false,
  arrows: false,
  fadeInArrows: false,
  autoPlay: false,
  autoPlayInterval: 4000,
};

export default withStyles(s)(Carousel);
